.tableList {
  background-color: var(--primary-background-color);
  height: 100%;
  width: 100%;
}
.tableList .tableListHeader {
  background-color: var(--ui-gray-darker);
  color: var(--text-secondary-color);
  display: flex;
  height: 63px;
  line-height: 63px;
  margin-top: 2px;
  overflow: hidden;
  width: 100%;
}
.tableList .tableListHeader .tableListHeaderTitle {
  color: var(--text-secondary-color);
  max-width: 230px;
  font-size: 22px;
  font-weight: 300;
  line-height: 63px;
  padding: 0 10px;
  text-align: left;
  flex: 1;
}
.tableList .tableListHeader .tableListHeaderSelector {
  cursor: pointer;
  max-width: 30px;
  padding-left: 2px;
  align-items: stretch;
  flex: 1;
  justify-content: space-around;
  text-align: center;
}
.tableList .tableListHeader .tableListHeaderSelector svg {
  fill: #c1d8e3;
  height: 63px;
  max-width: 11px;
}
.tableList .tableListHeader .numberOfItems {
  color: var(--ui-sky-blue);
  float: right;
  font-weight: 300;
  font-size: 40px;
  max-width: 54px;
  height: 63px;
  line-height: 66px;
  flex: 1;
}
